<template>
	<div>
		<div class="flex between">
			<div class="leftFloat">
				<h3 class="tit">过夜车辆数</h3>
				<div class="flex frequency">
					<div class="text"><span>{{ overNightPark }}</span><strong>车辆数值</strong></div>
					<echartscom id="inPark" v-if="overNightOneDay.chartData.length > 0" :nameData="overNightOneDay.rows"
											:chartData="overNightOneDay.chartData"></echartscom>
				</div>
			</div>
			<div class="leftFloat">
				<h3 class="tit">停车超过24小时车辆</h3>
				<div class="flex frequency">
					<div class="text"><span>{{ inPark }}</span><strong>车辆数值</strong></div>
					<echartscom id="exceed" v-if="entryCarOneDay.chartData.length > 0" :nameData="entryCarOneDay.rows"
											:chartData="entryCarOneDay.chartData"></echartscom>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
import {} from "@/api/api";

export default {
	name: "exceedDay",
	props: {
		entryCarOneDay: {
			type: Object
		},
		inPark: {
			type: Number
		},
		overNightOneDay: {
			type: Object
		},
		overNightPark: {
			type: Number
		}
	},
	data() {
		return {
			/*inPark: 10,
			entryCarOneDay: {
				rows: ["临时用户", "个人用户", "企业用户", "政府用户"],
				chartData: [335, 310, 234, 135]
			},*/
		};
	},
	mounted() {
	},
	methods: {},
	components: {
		"echartscom": () => import("../account/echartscom")
	}
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/overNight";
</style>
